import { useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import {
  Category,
  GetCategoriesDocument,
  GetCategoriesQuery,
} from '@redsleeve/oilynx-domain';

import { CategoryWithChildren } from '@components/category/domain';

import { Nullable } from '../../../../backend/src/lib/types';

export function useOrderedCategories(
  categoriesQueryData: GetCategoriesQuery
): Category[] {
  return useMemo(() => {
    if (!categoriesQueryData?.getCategories?.length) return [];
    const allCategories = categoriesQueryData.getCategories as Category[];
    return (
      allCategories
        // root categories
        .filter((category) => !category.parentId)
        .sort((a, b) => a.name.localeCompare(b.name))
        // add children
        .map((category) => ({
          category,
          children: allCategories
            .filter((subcategory) => subcategory.parentId === category.id)
            .sort((a, b) => a.name.localeCompare(b.name)),
        }))
        // flatten
        .reduce(
          (acc, it) => [...acc, it.category, ...it.children],
          [] as Category[]
        )
    );
  }, [categoriesQueryData]);
}

export function useCategories(): {
  orderedCategories: Category[];
  getCategory: (id: Category['id']) => Nullable<Category>;
  groupedCategories: CategoryWithChildren[];
} {
  const categoriesQuery = useQuery(GetCategoriesDocument, { ssr: false });
  const orderedCategories = useOrderedCategories(categoriesQuery.data);

  const categoriesMap = useMemo(
    () => new Map(orderedCategories.map((category) => [category.id, category])),
    [orderedCategories]
  );
  const getCategory = useCallback(
    (id) => categoriesMap.get(id),
    [categoriesMap]
  );
  const groupedCategories: CategoryWithChildren[] = useMemo(
    () =>
      orderedCategories.reduce((acc, it) => {
        if (!it.parentId) return [...acc, { ...it, children: [] }];
        acc.find((parent) => parent.id === it.parentId).children.push(it);
        return acc;
      }, [] as CategoryWithChildren[]),
    [orderedCategories]
  );

  return { orderedCategories, getCategory, groupedCategories };
}
