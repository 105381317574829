import { useFormik } from 'formik';

export * from './errors';
export * from './graphql';
export * from './security';
export * from './hooks/useModal';
export * from './hooks/useIsMobile';
export * from './hooks/useOrderedCategories';

export function NOOP() {}

export function isSSR() {
  return typeof window === 'undefined';
}

export function isNullOrUndefined(object: any): object is null | undefined {
  return object === undefined || object === null;
}

export type FormikControl = ReturnType<typeof useFormik>;

export enum TradesTableSort {
  ProductsASC = 'category.name',
  ProductsDESC = '-category.name',
  PriceASC = 'priceIndicationInUSDPerMt priceBenchmark.differentialType priceBenchmark.differentialInUSDPerMt priceBenchmark.differential currency quantityUnit priceIndication',
  PriceDESC = '-priceIndicationInUSDPerMt priceBenchmark.differentialType -priceBenchmark.differentialInUSDPerMt -priceBenchmark.differential currency quantityUnit -priceIndication',
  QuantityASC = 'quantityInMt quantityUnit quantity',
  QuantityDESC = '-quantityInMt quantityUnit -quantity',
  DeliveryWindowASC = 'deliveryWindowStart',
  DeliveryWindowDESC = '-deliveryWindowStart',
}

export enum ShippingsTableSort {
  NameASC = 'name',
  NameDESC = '-name',

  LastCargoASC = 'lastCargoCategory.name',
  LastCargoDESC = '-lastCargoCategory.name',

  SizeASC = 'size',
  SizeDESC = '-size',

  BuiltASC = 'built',
  BuiltDESC = '-built',

  TypeASC = 'type',
  TypeDESC = '-type',

  OpenWindowASC = 'openWindowStart',
  OpenWindowDESC = '-openWindowStart',
}
