import { useCallback, useRef, useState } from 'react';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

export type ModalState<TData> = {
  isOpen: boolean;
  data: TData | undefined;
};

export type ModalControl<TData = any> = {
  isOpen: boolean;
  data?: TData;
  open: (data?: TData) => void;
  close: () => void;
  toggle: () => void;
};

export function useModal<TData = any>(): ModalControl<TData> {
  const [{ isOpen, data }, setModalState] = useState({
    isOpen: false,
  } as ModalState<TData>);
  const isOpenRef = useRef(isOpen);
  useUpdateRefIfShallowNew(isOpenRef, isOpen);

  const open = useCallback(
    (data?: TData) => {
      setModalState({ isOpen: true, data });
    },
    [setModalState]
  );

  const close = useCallback(() => {
    setModalState({ isOpen: false, data: undefined });
  }, [setModalState]);

  const toggle = useCallback(() => {
    isOpenRef.current ? close() : open();
  }, [open, close, isOpenRef]);

  return {
    isOpen,
    data,
    open,
    close,
    toggle,
  };
}
