import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
  ServerError,
} from '@apollo/client';
import { DocumentNode, FetchResult } from '@apollo/client/link/core/types';
import { onError } from '@apollo/client/link/error';
import * as fetch from 'isomorphic-fetch';
import * as React from 'react';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';

const cache = new InMemoryCache();

const { link: statusNotifierLink, useApolloNetworkStatus: statusNotifierHook } =
  createNetworkStatusNotifier();

function createLinks(): ApolloLink[] {
  if (typeof window === 'undefined') return [];

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        'x-token': localStorage.getItem('x-token') || null,
        'x-refresh-token': localStorage.getItem('x-refresh-token') || null,
      },
    });

    return forward!(operation);
  });

  const authAfterware = new ApolloLink((operation, forward) =>
    forward(operation).map((response: FetchResult) => {
      const {
        response: { headers },
      } = operation.getContext();
      const newAccessToken = headers && headers.get('x-token');
      newAccessToken && localStorage.setItem('x-token', newAccessToken);

      return response;
    })
  );

  const logoutLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors
        .filter((graphQLError) => graphQLError.message !== 'Not authenticated')
        .forEach((graphQLError) =>
          console.error('Server request error', graphQLError)
        );
    if (networkError) console.error('Server communication error', networkError);

    // TODO handle UNAUTHENTICATED (redirect to login with old link, do NOT double redirect on get current user)
    // TODO format user input error

    if (networkError && (networkError as ServerError).statusCode === 401) {
      // TODO some global error management
      localStorage.removeItem('x-token');
      localStorage.removeItem('x-refresh-token');

      window.location.href = '/';
    }
  });

  return [
    statusNotifierLink,
    logoutLink,
    authMiddleware,
    authAfterware,
    new HttpLink({
      uri: process.env.SERVER_URL,
      fetch: fetch as any,
    }),
  ];
}

export const appApolloClient = new ApolloClient({
  link: from(createLinks()),
  cache,
});

export const gqlWrapper = ({ element }: { element: React.ReactNode }) => (
  <ApolloProvider client={appApolloClient}>{element}</ApolloProvider>
);

export const useApolloNetworkStatus = statusNotifierHook;

export function getQueryName(document: DocumentNode): string {
  return (document.definitions[0] as any).name.value;
}
