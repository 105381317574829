// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-containers-404-tsx": () => import("./../../../src/containers/404.tsx" /* webpackChunkName: "component---src-containers-404-tsx" */),
  "component---src-containers-activate-account-index-tsx": () => import("./../../../src/containers/activate-account/index.tsx" /* webpackChunkName: "component---src-containers-activate-account-index-tsx" */),
  "component---src-containers-admin-category-management-tsx": () => import("./../../../src/containers/admin/category-management.tsx" /* webpackChunkName: "component---src-containers-admin-category-management-tsx" */),
  "component---src-containers-admin-price-index-management-tsx": () => import("./../../../src/containers/admin/price-index-management.tsx" /* webpackChunkName: "component---src-containers-admin-price-index-management-tsx" */),
  "component---src-containers-admin-signup-management-tsx": () => import("./../../../src/containers/admin/signup-management.tsx" /* webpackChunkName: "component---src-containers-admin-signup-management-tsx" */),
  "component---src-containers-admin-user-management-tsx": () => import("./../../../src/containers/admin/user-management.tsx" /* webpackChunkName: "component---src-containers-admin-user-management-tsx" */),
  "component---src-containers-blog-tsx": () => import("./../../../src/containers/blog.tsx" /* webpackChunkName: "component---src-containers-blog-tsx" */),
  "component---src-containers-contact-tsx": () => import("./../../../src/containers/contact.tsx" /* webpackChunkName: "component---src-containers-contact-tsx" */),
  "component---src-containers-cookie-policy-tsx": () => import("./../../../src/containers/cookie-policy.tsx" /* webpackChunkName: "component---src-containers-cookie-policy-tsx" */),
  "component---src-containers-disclaimer-tsx": () => import("./../../../src/containers/disclaimer.tsx" /* webpackChunkName: "component---src-containers-disclaimer-tsx" */),
  "component---src-containers-home-index-tsx": () => import("./../../../src/containers/home/index.tsx" /* webpackChunkName: "component---src-containers-home-index-tsx" */),
  "component---src-containers-login-tsx": () => import("./../../../src/containers/login.tsx" /* webpackChunkName: "component---src-containers-login-tsx" */),
  "component---src-containers-notifications-tsx": () => import("./../../../src/containers/notifications.tsx" /* webpackChunkName: "component---src-containers-notifications-tsx" */),
  "component---src-containers-our-story-tsx": () => import("./../../../src/containers/our-story.tsx" /* webpackChunkName: "component---src-containers-our-story-tsx" */),
  "component---src-containers-profile-delete-account-tsx": () => import("./../../../src/containers/profile/delete-account.tsx" /* webpackChunkName: "component---src-containers-profile-delete-account-tsx" */),
  "component---src-containers-profile-disabled-tsx": () => import("./../../../src/containers/profile/disabled.tsx" /* webpackChunkName: "component---src-containers-profile-disabled-tsx" */),
  "component---src-containers-profile-index-tsx": () => import("./../../../src/containers/profile/index.tsx" /* webpackChunkName: "component---src-containers-profile-index-tsx" */),
  "component---src-containers-profile-interests-tsx": () => import("./../../../src/containers/profile/interests.tsx" /* webpackChunkName: "component---src-containers-profile-interests-tsx" */),
  "component---src-containers-profile-notifications-tsx": () => import("./../../../src/containers/profile/notifications.tsx" /* webpackChunkName: "component---src-containers-profile-notifications-tsx" */),
  "component---src-containers-profile-password-tsx": () => import("./../../../src/containers/profile/password.tsx" /* webpackChunkName: "component---src-containers-profile-password-tsx" */),
  "component---src-containers-profile-under-review-tsx": () => import("./../../../src/containers/profile/under-review.tsx" /* webpackChunkName: "component---src-containers-profile-under-review-tsx" */),
  "component---src-containers-request-reset-password-tsx": () => import("./../../../src/containers/request-reset-password.tsx" /* webpackChunkName: "component---src-containers-request-reset-password-tsx" */),
  "component---src-containers-reset-password-tsx": () => import("./../../../src/containers/reset-password.tsx" /* webpackChunkName: "component---src-containers-reset-password-tsx" */),
  "component---src-containers-shipping-index-tsx": () => import("./../../../src/containers/shipping/index.tsx" /* webpackChunkName: "component---src-containers-shipping-index-tsx" */),
  "component---src-containers-signup-tsx": () => import("./../../../src/containers/signup.tsx" /* webpackChunkName: "component---src-containers-signup-tsx" */),
  "component---src-containers-terms-and-conditions-tsx": () => import("./../../../src/containers/terms-and-conditions.tsx" /* webpackChunkName: "component---src-containers-terms-and-conditions-tsx" */),
  "component---src-containers-thank-you-tsx": () => import("./../../../src/containers/thank-you.tsx" /* webpackChunkName: "component---src-containers-thank-you-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

