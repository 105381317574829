module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Oilynx","short_name":"Oilynx","start_url":"/","background_color":"#313546","theme_color":"#5289FF","display":"standalone","icon":"src/resources/Oilynx LogoMark - Main svg.svg","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"appendScript":"/home/runner/work/oilynx/oilynx/packages/www/src/notifications.worker.js"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-Y3W35737EE"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
