import '@fontsource/nunito/300.css';
import '@fontsource/nunito/700.css';
import '@fontsource/roboto';

import { tradesAndShippingsContextWrapper } from './src/containers/TradesAndShippingsContext';
import { gqlWrapper, securityWrapper } from './src/lib';
import './src/styles/global.css';

export const wrapRootElement = ({ element }) =>
  gqlWrapper({
    element: securityWrapper({
      element: tradesAndShippingsContextWrapper({ element }),
    }),
  });

export const onRouteUpdate = () => {
  typeof navigator !== 'undefined' &&
    navigator.serviceWorker.register('/sw.js').then((reg) => {
      reg.update();
    });
};

export const onServiceWorkerUpdateReady = () => {
  typeof window !== 'undefined' && window.location.reload(true);
};
