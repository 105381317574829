import { useQuery } from '@apollo/client';
import { NOOP } from '@lib';
import { useFormik } from 'formik';
import * as React from 'react';
import { createContext, FC, useState } from 'react';

import {
  GetShippingsDocument,
  GetTradesDocument,
} from '@redsleeve/oilynx-domain';

export const TradesAndShippingsContext = createContext<{
  shippingSort;
  setShippingSort;
  shippingsFilterFormik;
  getShippingsQuery;

  tradesSort;
  setTradesSort;
  tradesFilterFormik;
  getTradesQuery;
}>({
  shippingSort: '',
  setShippingSort: () => undefined,
  shippingsFilterFormik: {},
  getShippingsQuery: {},

  tradesSort: '',
  setTradesSort: () => undefined,
  tradesFilterFormik: {},
  getTradesQuery: {},
});

export const TradesAndShippingsContextProvider: FC = ({ children }) => {
  const shippingsFilterFormik = useFormik({
    initialValues: {
      categories: [],
      types: [],
      minSize: '' as number | '',
      maxSize: '' as number | '',
      minBuilt: '' as number | '',
      maxBuilt: '' as number | '',
      openWindowStart: undefined as Date,
      openWindowEnd: undefined as Date,
    },
    onSubmit: NOOP,
  });
  const [shippingSort, setShippingSort] = useState('-_id');
  const getShippingsQuery = useQuery(GetShippingsDocument, {
    ssr: false,
    variables: {
      pagination: {
        first: 50,
        offset: 0,
        sort: shippingSort,
      },
      filter: {
        categories: shippingsFilterFormik.values.categories,
        types: shippingsFilterFormik.values.types,
        minSize: shippingsFilterFormik.values.minSize || undefined,
        maxSize: shippingsFilterFormik.values.maxSize || undefined,
        minBuilt: shippingsFilterFormik.values.minBuilt || undefined,
        maxBuilt: shippingsFilterFormik.values.maxBuilt || undefined,
        openWindowStart:
          shippingsFilterFormik.values.openWindowStart?.getTime() || undefined,
        openWindowEnd:
          shippingsFilterFormik.values.openWindowEnd?.getTime() || undefined,
      },
    },
  });

  const tradesFilterFormik = useFormik({
    initialValues: {
      categories: [],
      incoterms: [],
      minQuantityInMT: '' as number | '',
      maxQuantityInMT: '' as number | '',
      priceBenchmark: '',
      minPriceIndicationInDollarsPerMT: '' as number | '',
      maxPriceIndicationInDollarsPerMT: '' as number | '',
      deliveryWindowStart: undefined as Date,
      deliveryWindowEnd: undefined as Date,
    },
    onSubmit: NOOP,
  });
  const [tradesSort, setTradesSort] = useState('-_id');
  const getTradesQuery = useQuery(GetTradesDocument, {
    ssr: false,
    variables: {
      pagination: {
        first: 50,
        offset: 0,
        sort: tradesSort,
      },
      filter: {
        categories: tradesFilterFormik.values.categories,
        incoterms: tradesFilterFormik.values.incoterms,
        minQuantityInMT: tradesFilterFormik.values.minQuantityInMT || undefined,
        maxQuantityInMT: tradesFilterFormik.values.maxQuantityInMT || undefined,
        priceBenchmark: tradesFilterFormik.values.priceBenchmark || undefined,
        minPriceIndicationInDollarsPerMT:
          tradesFilterFormik.values.minPriceIndicationInDollarsPerMT ||
          undefined,
        maxPriceIndicationInDollarsPerMT:
          tradesFilterFormik.values.maxPriceIndicationInDollarsPerMT ||
          undefined,
        deliveryWindowStart:
          tradesFilterFormik.values.deliveryWindowStart?.getTime() || undefined,
        deliveryWindowEnd:
          tradesFilterFormik.values.deliveryWindowEnd?.getTime() || undefined,
      },
    },
  });

  return (
    <TradesAndShippingsContext.Provider
      value={{
        shippingSort,
        setShippingSort,
        shippingsFilterFormik,
        getShippingsQuery,

        tradesSort,
        setTradesSort,
        tradesFilterFormik,
        getTradesQuery,
      }}
    >
      {children}
    </TradesAndShippingsContext.Provider>
  );
};

export const tradesAndShippingsContextWrapper = ({
  element,
}: {
  element: React.ReactNode;
}) => {
  return (
    <TradesAndShippingsContextProvider>
      {element}
    </TradesAndShippingsContextProvider>
  );
};
