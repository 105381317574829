import { useApolloClient, useQuery } from '@apollo/client';
import { FC, useCallback, useState } from 'react';
import * as React from 'react';

import {
  CurrentUserDocument,
  CurrentUserQuery,
  UserRole,
} from '@redsleeve/oilynx-domain';

import { ModalControl, useModal } from '@lib/hooks/useModal';
import { NOOP } from '@lib/index';

export const SecurityContext = React.createContext<{
  currentUser: CurrentUserQuery['currentUser'] | undefined;
  isSuperAdmin: boolean;
  loginControl: ModalControl;
  resetPasswordControl: ModalControl;
  signupControl: ModalControl;
  notificationsControl: ModalControl;
  logout: () => Promise<void>;
  webPushPublicKey?: string;
  setWebPushPublicKey: React.Dispatch<React.SetStateAction<string>>;
}>({
  currentUser: undefined,
  isSuperAdmin: false,
  loginControl: undefined as ModalControl,
  resetPasswordControl: undefined as ModalControl,
  signupControl: undefined as ModalControl,
  notificationsControl: undefined as ModalControl,
  logout: () => undefined,
  setWebPushPublicKey: () => undefined,
});

const SecurityProvider: FC = ({ children }) => {
  const apolloClient = useApolloClient();

  const currentUserQuery = useQuery(CurrentUserDocument);

  const loginControl = useModal();
  const resetPasswordControl = useModal();
  const signupControl = useModal();
  const notificationsControl = useModal();
  const [webPushPublicKey, setWebPushPublicKey] = useState<string | undefined>(
    undefined
  );

  const logout = useCallback(async () => {
    localStorage.removeItem('x-token');
    localStorage.removeItem('x-refresh-token');

    try {
      await apolloClient.resetStore();
    } catch {
      NOOP(); // this will fail on currentUser
    }
  }, [apolloClient]);

  return (
    <SecurityContext.Provider
      value={{
        currentUser: currentUserQuery.data?.currentUser,
        isSuperAdmin:
          currentUserQuery.data?.currentUser?.roles &&
          currentUserQuery.data.currentUser.roles.indexOf(
            UserRole.SuperAdmin
          ) !== -1,
        loginControl,
        logout,
        resetPasswordControl,
        signupControl,
        notificationsControl,
        webPushPublicKey,
        setWebPushPublicKey,
      }}
    >
      {children}
    </SecurityContext.Provider>
  );
};

export const securityWrapper = ({ element }: { element: React.ReactNode }) => (
  <SecurityProvider>{element}</SecurityProvider>
);
