import { ApolloError } from '@apollo/client';
import { FormikErrors } from 'formik';

import { Maybe } from '@redsleeve/oilynx-domain';

import { NOOP } from '@lib/index';

type SimpleGraphQLFieldError<FormValues> = keyof FormValues;
type NamedGraphQLFieldError<FormValues> = {
  field: keyof FormValues;
  code: string;
};

export type GraphQLFieldError<FormValues> =
  | SimpleGraphQLFieldError<FormValues>
  | NamedGraphQLFieldError<FormValues>;

function isSimpleError<FormValues>(
  error: GraphQLFieldError<FormValues>
): error is SimpleGraphQLFieldError<FormValues> {
  return typeof error === 'string';
}

export function getInvalidFields<FormValues>(
  error?: ApolloError
): Maybe<FormikErrors<FormValues>> {
  if (!error) {
    return undefined;
  }

  if (error.graphQLErrors) {
    const errors = error.graphQLErrors
      .filter(
        (err) => err.extensions && err.extensions.code === 'BAD_USER_INPUT'
      )
      .reduce((acc, it) => {
        const invalidArgs = it.extensions!.exception.invalidArgs;
        if (invalidArgs) {
          invalidArgs.forEach((fieldError: GraphQLFieldError<FormValues>) => {
            if (isSimpleError(fieldError)) {
              acc[fieldError] = it.extensions!.message || 'Invalid value';
            } else {
              acc[fieldError.field] =
                fieldError.code || ('Invalid value' as any); // TODO fix types
            }
          });
        }
        return acc;
      }, {} as FormikErrors<FormValues>);

    return Object.keys(errors).length ? errors : undefined;
  }

  return undefined;
}

export function mapInvalidFieldInvalidFieldNames<FormValues>(
  errors: Maybe<FormikErrors<FormValues>>,
  namesMap: Map<string, string>
): FormikErrors<FormValues> {
  try {
    return Object.entries(errors)
      .map<[string, unknown]>(([fieldName, error]) => [
        namesMap.get(fieldName) ?? fieldName,
        error,
      ])
      .reduce(
        (acc, it) => ({ ...acc, [it[0]]: it[1] }),
        {} as FormikErrors<FormValues>
      );
  } catch {
    return undefined;
  }
}

export function getErrorMessages(error: ApolloError): string[] | null {
  if (!error) {
    return null;
  }

  try {
    if (error.graphQLErrors) {
      const errors = error.graphQLErrors
        .map((err) => err.message)
        .filter((err) => !!err);

      return errors && errors.length ? errors : null;
    }
  } catch {
    NOOP();
  }
  return null;
}
